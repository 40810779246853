import React, {useLayoutEffect} from 'react';
import styled from 'styled-components';
import Link from "gatsby-link";
import SanityImage  from "gatsby-plugin-sanity-image";
import theme from "styles/style.js";
import video from "assets/icons/video.svg";
import photo from "assets/icons/photo.svg"


export const SectionGallery = styled.section`
  display: grid;
  padding-top: 3em;
  grid-template-columns: 50% 50%;
  column-gap: 1em;
  row-gap: 1em;
  img {
    //filter: grayscale(1);
  }
  @media (max-width: ${theme.device} ){
    grid-template-columns: 100%;
  }
`

export const Gallery = ({works} ) => {

    let cursor = null;

    useLayoutEffect(()=>{
        cursor = document.getElementById("mainCursor");
    }, [])

    function biggerCircle() {
        if(cursor) {
            cursor.classList.add('circle--bigger');
        }
    }
    function backCircle(){
        if(cursor) {
            cursor.classList.remove('circle--bigger');
        }
    }

    const works_render = []

    works.forEach((work, index) => (
        works_render.push(<div key={index} onMouseOver={biggerCircle} onMouseLeave={backCircle} >
            <Link onClick={backCircle} to={`/nuestro-trabajo/${work.node.slug.current}/`} title={work.node.title}>
                <div className="type-work">
                   { work.node.is_photo && <img src={photo} width="30" height="30" alt="Trabajo fotografico" title="trabajo fotografico" />}
                   { work.node.codeVideo && <img src={video} width="30" height="30" alt="Producción audiovisual" title="trabajo audiovisual" /> }
                </div>
                <SanityImage  {...work.node.mainImage} width={500} height={300} alt={work.node.title}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }} />
            </Link>
        </div>)
    ))
    return (
        <SectionGallery>
            {works_render}
        </SectionGallery>
    );
};

export default Gallery;