import React, {createRef} from 'react';
import Layout from 'components/common/layout/layout';
import HeroPortFolio from "components/portafolio/hero/hero-portfolio";
import Gallery from "components/portafolio/gallery/Gallery";
import Contacto from "components/common/contacto/Contacto";
import {graphql} from "gatsby";
import theme from "styles/style";
import { SEO, StructuredData } from 'components/common/SEO';

const IndexPage = ( { data } ) => {

    const start = createRef(null);
    const block_1 = createRef(null);
    const end = createRef(null);

    const adjust = {
      start: {left: 50, color: `${theme.orange}`, size: 0, center: false, animation: false},
      block_1:  {left: 0, color: `${theme.green}`, size: 0, center: false, animation: true}
    }


    return (
      <>
      <SEO
            title = "Portfolio de proyectos de vídeo y fotografía Obtura"
            description= "Entra y echa un vistazo a todos los proyectos que hemos realizado. ¿Quieres que nos encarguemos del tuyo? Podemos ayudarte."
            location = 'nuestro-trabajo'
            canonical = 'nuestro-trabajo'
            robots = 'index, follow'
        />
        <Layout  start={start} block_1={block_1} end={end} adjust={adjust} >
            <div className={"circle__dawn"}>
            <div className={"container "} ref={start}></div>
            <HeroPortFolio title={"Trabajos Obtura"} to={"#works"} >
                Aquí puedes encontrar todos los proyectos de producción audiovisual y fotográfico que hemos realizado. Si necesitas que nos encarguemos del tuyo ponte en contacto con nosotros. ¡Podemos ayudarte!
            </HeroPortFolio>
            </div>
            <div className={"container"} id={"works"} ref={block_1}  >
                <Gallery works={data.allSanityPortfolio.edges} />
            </div>
            <div className={"container padding-xl"} ref={end}>
                <Contacto />
            </div>
        </Layout>
        </>
    )
}

export const query = graphql`
  query PortfolioPageQuery {
      allSanityPortfolio(sort: {order: DESC, fields: publishedAt}) {
        edges {
          node {
            codeVideo
            is_photo
            title
            mainImage {
              ... ImageWithPreview
            }
            publishedAt
            slug {
              current
            }
            categories {
                          id
                          title
                        }
          }
        }
      }
  }
`

export default IndexPage
