import React from 'react';
import styled from 'styled-components';
import ButtonDown from "components/ui/atoms/ButtonDown";
import theme from "styles/style";

export const DivHero = styled.div`
  position: relative;
  padding-top: 200px;
  padding-bottom: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: ${theme.backgroundDarkColor };
  *{
    z-index:1;
  }
  .title-project{
    text-transform: uppercase;
    width: 50%;
    @media (max-width:1200px){
        width:70%;    
    }
    @media (max-width:500px){
        width: 90%;    
    }
  }
  .bloque{
    width: 50%;
    text-align: center;
    margin:0;
    padding: 0;
    @media (max-width:1200px){
        width:70%;    
    }
    @media (max-width:500px){
        width:90%;    
    }
  }
`

export const HeroPortFolio = ({title, to, children }) => {
    return (
        <>

        <DivHero>
            <h1 className={"text-center title-project"}> {title} </h1>
            <div className={"bloque"}>
                {children}
            </div>
            <ButtonDown to={to} />
        </DivHero>
        </>

    );
};;

export default HeroPortFolio;